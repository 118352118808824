@font-face {
  font-family: "Urbanist";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local(""),
    url("../assets/fonts/urbanist-v8-latin-regular.woff2") format("woff2"),
    url("../assets/fonts/urbanist-v8-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Urbanist";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local(""),
    url("../assets/fonts/urbanist-v8-latin-700.woff2") format("woff2"),
    url("../assets/fonts/urbanist-v8-latin-700.woff") format("woff");
}

@font-face {
  font-family: "Urbanist";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: local(""),
    url("../assets/fonts/urbanist-v8-latin-900.woff2") format("woff2"),
    url("../assets/fonts/urbanist-v8-latin-900.woff") format("woff");
}
