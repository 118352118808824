@font-face {
  font-family: Urbanist;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local(""), url("urbanist-v8-latin-regular.35d414cd.woff2") format("woff2"), url("urbanist-v8-latin-regular.cf566825.woff") format("woff");
}

@font-face {
  font-family: Urbanist;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local(""), url("urbanist-v8-latin-700.a02e3e32.woff2") format("woff2"), url("urbanist-v8-latin-700.eb9a8a8d.woff") format("woff");
}

@font-face {
  font-family: Urbanist;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: local(""), url("urbanist-v8-latin-900.c0b2651d.woff2") format("woff2"), url("urbanist-v8-latin-900.7602fd9a.woff") format("woff");
}

/*# sourceMappingURL=index.16839a93.css.map */
